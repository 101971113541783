<template lang="pug">
    .bdi-layouts
        GeneralSidebar
        .bdi-layouts__content
            //- header page
            HeaderPage(title="ACL")
            //- form filter
            .layout-general 
                .block.w-full
                    form.card-bg-theme.p-6.rounded.shadow.w-full(@submit="submit($event)")
                        .flex.items-center.pb-6.justify-end
                            b-button.mr-4(text="Batalkan perubahan" type="button" @click.native="reset()")
                            b-button(text="Simpan perubahan" kind="primary" type='submit')
                        
                        table.table.table--roles
                            thead(v-if="tableThead.length > 0")
                                tr
                                    th(v-for="(th,index) in tableThead" :key="index")
                                        span(v-if="th.name && th.name === 'Fitur'") {{ th.name }}

                                        //- b-checkbox(v-else :value="th.id.toString()" :label="th.name" v-model="checkedAllRoles" @change="roleCheckAll($event,th.id)")

                                        .ui-checkbox(v-else v-tooltip="'Centang/hilangkan centang semua fitur untuk role '+ th.name")
                                            input(type="checkbox" :value="th.id.toString()" :label="th.name" v-model="checkedAllRoles" @change="roleCheckAll($event,th.id)")
                                            .ui-checkbox-style
                                                .box-checkbox.box-checkbox--check
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                label {{ th.name }}

                            tbody
                                tr(v-if="status_list.status === 'loading' || status_list_role_access_control.status === 'loading'")
                                    td.text-center(:colspan="tableThead.length")
                                        .relative.p-10
                                            b-loading(text="Memuat data")

                                template(v-else-if="list && list.length > 0 && list_role_access_control && list_role_access_control.length > 0")
                                    template(v-for="(mod,index) in list")
                                        tr.as-parent
                                            td {{ mod.name }}
                                            td(v-for="role in list_role_access_control" :key="role.id")
                                                //- b-checkbox(@change="parentCheck($event,mod.id,role.id)" :value="mod.id+'_'+role.id" v-model="parentChecked")

                                                .ui-checkbox(v-tooltip="'Centang/hilangkan centang semua sub fitur '+ mod.name +' untuk role '+ role.name")
                                                    input(type="checkbox" @change="parentCheck($event,mod.id,role.id)" :value="mod.id+'_'+role.id" v-model="parentChecked")
                                                    .ui-checkbox-style
                                                        .box-checkbox.box-checkbox--check
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                        label

                                        template(v-if="mod.sub_feature && mod.sub_feature !== null && mod.sub_feature.length > 0")
                                            tr(v-for="(sub_mod,index) in mod.sub_feature")
                                                td {{ sub_mod.name }}
                                                td(v-for="role in list_role_access_control" :key="role.id")
                                                    b-checkbox(:class="'module-'+ mod.id +'-role-'+ role.id" :value="mod.id+'_'+role.id+'_'+sub_mod.sub_feature_id" v-model="checkedData")

                                tr(v-else)
                                    td.text-center(:colspan="tableThead.length") Data tidak ditemukan.

                        //- table.table.table--roles
                            thead
                                tr
                                    th Fitur
                                    th: b-checkbox(name="roles" label="Role A")
                                    th: b-checkbox(name="roles" label="Role B")
                                    th: b-checkbox(name="roles" label="Role C")
                                    th: b-checkbox(name="roles" label="Role E")
                                    th: b-checkbox(name="roles" label="Role F")
                            tbody
                                tr.as-parent
                                    td Management Users
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Index 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Update 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Delete 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")

                                tr.as-parent
                                    td Manajemen Topik
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Index 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Update 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Delete 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")

                                tr.as-parent
                                    td BDI Organisasi
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Index 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Update 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Delete 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")

                                tr.as-parent
                                    td BDI Tokoh
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Index 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Update 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Delete 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")

                                tr.as-parent
                                    td BDI Isu
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Index 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Update 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Delete 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")

                                tr.as-parent
                                    td Gatra
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Index 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Update 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                tr
                                    td Delete 
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")
                                    td: b-checkbox(name="roles")

                        .flex.items-center.pt-6.justify-end
                            b-button.mr-4(text="Batalkan perubahan" type="button" @click.native="reset()")
                            b-button(text="Simpan perubahan" kind="primary" type='submit')
</template>

<script>
import { mapState } from 'vuex';
import GeneralSidebar from '@/pages/general/Sidebar';
import HeaderPage from '@/components/sections/HeaderPage';

export default {
    name: 'GeneralRolesShell',
    components: {
        GeneralSidebar,
        HeaderPage
    },
    data() {
        return {
            checkedAllRoles: [],
            parentChecked: [],
            checkedData: [],
            tableThead: [],
        }
    },
    computed: {
        ...mapState('features', [
            'status_list',
            'list',
        ]),
        ...mapState('acl', [
            'status_list_role_access_control',
            'list_role_access_control',
        ]),
    },
    watch: {
        list() {
            // this.checkCheckedData();
        },
        list_role_access_control(newVal) {
            // console.log(newVal)
            // let tHead = [];

            // if (newVal && newVal.length > 0) {
            //     tHead.push({ name: 'Fitur' });

            //     for (var i = 0; i < newVal.length; i++) {
            //         tHead.push(newVal[i]);
            //     }

            //     console.log(tHead)


            // }

            // this.tableThead = tHead;

            this.checkCheckedData();
        },
    },
    methods: {
        checkCheckedData() {
            // check checked value

            if (this.list && this.list.length > 0 && this.list_role_access_control && this.list_role_access_control.length > 0) {

                let tHead = [{ name: 'Fitur' }];

                // tHead.push({ name: 'Fitur' });

                for (var i = 0; i < this.list_role_access_control.length; i++) {
                    tHead.push(this.list_role_access_control[i]);
                }

                // console.log(tHead)
                this.tableThead = tHead;

                var modules = this.list;
                var roles = this.list_role_access_control;
                let checked = [];
                
                if (modules.length > 0) {
                    for (var i = 0; i < modules.length; i++) {
                        var sub_features = modules[i].sub_feature;
                        
                        if (sub_features && sub_features.length > 0) {
                            for (var j = 0; j < sub_features.length; j++) {
                                // sub_features[j]

                                if (roles.length > 0) {
                                    for (var k = 0; k < roles.length; k++) {
                                        // roles[k]

                                        if (roles[k].access_control && roles[k].access_control !== null && roles[k].access_control.filter(function(item) { 
                                            return item.feature_id === modules[i].id && item.sub_feature_id === sub_features[j].sub_feature_id && item.status === true;
                                        }).length > 0)

                                            checked.push(modules[i].id +'_'+ roles[k].id +'_'+ sub_features[j].sub_feature_id);
                                    }
                                }
                            }
                        }
                    }
                }

                this.checkedData = checked;
            } else {
                this.tableThead = [];
            }
        },
        parentCheck(e,module_id,role_id) {
            // console.log(e)

            if (Object.keys(this.list).length > 0 && Object.keys(this.list_role_access_control).length > 0) {
                var modules = this.list;
                var roles = this.list_role_access_control;
                let checkedData = this.checkedData;
                let splitCheckedData;
                let index;

                if (modules.length > 0) {
                    for (var i = 0; i < modules.length; i++) {
                        var sub_features = modules[i].sub_feature;
                        
                        if (sub_features && sub_features !== null && sub_features.length > 0) {
                            for (var j = 0; j < sub_features.length; j++) {
                                // sub_features[j]

                                if (roles.length > 0) {
                                    for (var k = 0; k < roles.length; k++) {
                                        // roles[k]

                                        if (e.target.checked) {

                                            if (modules[i].id == module_id && roles[k].id == role_id && checkedData.filter(function(item) { 
                                                splitCheckedData = item.split("_");

                                                return splitCheckedData[0] == module_id && splitCheckedData[1] == role_id && splitCheckedData[2] == sub_features[j].sub_feature_id;
                                            }).length > 0) {
                                                // console.log(modules[i].id +'_'+ roles[k].id +'_'+ sub_features[j].sub_feature_id);

                                                // remove array
                                                index = checkedData.indexOf(modules[i].id +'_'+ roles[k].id +'_'+ sub_features[j].sub_feature_id);
                                                if (index > -1)
                                                  checkedData.splice(index, 1);
                                            }

                                            if (modules[i].id == module_id && roles[k].id == role_id)
                                                // console.log('on parentCheck() checked');
                                                checkedData.push(modules[i].id +'_'+ roles[k].id +'_'+ sub_features[j].sub_feature_id);

                                        } else {

                                            if (modules[i].id == module_id && roles[k].id == role_id) {
                                                // console.log('on parentCheck() unchecked');

                                                index = checkedData.indexOf(modules[i].id +'_'+ roles[k].id +'_'+ sub_features[j].sub_feature_id);
                                                if (index > -1)
                                                  checkedData.splice(index, 1);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                this.checkedData = checkedData;
            }
        },
        roleCheckAll(e,role_id) {
            if (Object.keys(this.list).length > 0 && Object.keys(this.list_role_access_control).length > 0) {
                var modules = this.list;
                var roles = this.list_role_access_control;
                let parentChecked = this.parentChecked;
                let checkedData = this.checkedData;
                let splitParentChecked;
                let splitCheckedData;
                let index;

                if (modules.length > 0) {
                    for (var i = 0; i < modules.length; i++) {
                        var sub_features = modules[i].sub_feature;
                        
                        if (sub_features.length > 0) {
                            for (var j = 0; j < sub_features.length; j++) {
                                // sub_features[j]

                                if (roles.length > 0) {
                                    for (var k = 0; k < roles.length; k++) {
                                        // roles[k]

                                        if (e.target.checked) {

                                            if (roles[k].id == role_id && parentChecked.filter(function(item) { 
                                                splitParentChecked = item.split("_");
                                                return splitParentChecked[1] == role_id;
                                            }).length > 0) {
                                                // remove array
                                                index = parentChecked.indexOf(modules[i].id +'_'+ roles[k].id);
                                                if (index > -1)
                                                  parentChecked.splice(index, 1);
                                            }

                                            if (roles[k].id == role_id && checkedData.filter(function(item) { 
                                                splitCheckedData = item.split("_");
                                                return splitCheckedData[1] == role_id;
                                            }).length > 0) {
                                                // remove array
                                                index = checkedData.indexOf(modules[i].id +'_'+ roles[k].id +'_'+ sub_features[j].sub_feature_id);
                                                if (index > -1)
                                                  checkedData.splice(index, 1);
                                            }

                                            if (roles[k].id == role_id) {
                                                parentChecked.push(modules[i].id +'_'+ roles[k].id);
                                                checkedData.push(modules[i].id +'_'+ roles[k].id +'_'+ sub_features[j].sub_feature_id);
                                            }

                                        } else {

                                            if (roles[k].id == role_id) {
                                                index = parentChecked.indexOf(modules[i].id +'_'+ roles[k].id);
                                                if (index > -1)
                                                  parentChecked.splice(index, 1);

                                                index = checkedData.indexOf(modules[i].id +'_'+ roles[k].id +'_'+ sub_features[j].sub_feature_id);
                                                if (index > -1)
                                                  checkedData.splice(index, 1);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                this.parentChecked = parentChecked;
                this.checkedData = checkedData;
            }
        },
        reset() {
            this.$swal.fire({
                icon: 'warning',
                title: 'Apakah anda benar-benar ingin mengembalikan semua data yang telah diubah?',
                // text: id,
                confirmButtonText: 'Ya!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {
                if (result.isConfirmed)
                    this.getData();
            });
        },
        async getData() {
            // Event.$emit('showLoading', true);
            this.checkedAllRoles = [];
            this.parentChecked = [];

            await this.$store.commit('features/setList', {});
            await this.$store.commit('acl/setListRoleAccessControl', {});

            await this.$store.dispatch('features/getList', [1000, 1]);
            await this.$store.dispatch('acl/getListRoleAccessControl', [1000, 1]);
            // Event.$emit('showLoading', false);
        },
        submit(e) {
            e.preventDefault();
            // console.log(this.checkedData);

            Event.$emit('showLoading', true);

            // let roles = (this.list_role_access_control && this.list_role_access_control.length > 0 ? this.list_role_access_control : []);
            let checkedData = this.checkedData;

            if (checkedData.length > 0) {
                let data = [];
                let splitCheckedData;
                // let find;
                // let current_role_index;

                // data = roles.map(function(item) {
                //     return {
                //         role_id: item.id,
                //         function: [],
                //     };
                // });


                // if (checkedData.length > 0) {
                    for (var i = 0; i < checkedData.length; i++) {

                        splitCheckedData = checkedData[i].split("_");

                        data.push({
                            role_id: parseInt(splitCheckedData[1]),
                            sub_feature_id: splitCheckedData[2],
                            feature_id: splitCheckedData[0],
                            status: true
                        });

                        // if (data.length > 0) {
                        //     find = false;
                        //     current_role_index = null;
                        //     for (var j = 0; j < data.length; j++) {
                        //         if (data[j].role_id === parseInt(splitCheckedData[1])){
                        //             find = true;
                        //             current_role_index = j;
                        //         }
                        //     }

                        //     if (find)
                        //         data[current_role_index].function.push(parseInt(splitCheckedData[2]));
                        // }
                    }
                // }

                if (this.list_role_access_control && this.list_role_access_control.length > 0) {
                    let old_access_control = this.list_role_access_control;

                    for (var j = 0; j < old_access_control.length; j++) {
                        // old_access_control[j]
                        
                        if (old_access_control[j].access_control && old_access_control[j].access_control !== null) {

                            for (var k = 0; k < old_access_control[j].access_control.length; k++) {
                                // old_access_control[j].access_control[k]

                                let check_data = data.filter(function(item) {
                                    return item.role_id === old_access_control[j].id && item.feature_id === old_access_control[j].access_control[k].feature_id && item.sub_feature_id === old_access_control[j].access_control[k].sub_feature_id;
                                });

                                if (check_data.length === 0) {
                                    data.push({
                                        role_id: old_access_control[j].id,
                                        sub_feature_id: old_access_control[j].access_control[k].sub_feature_id,
                                        feature_id: old_access_control[j].access_control[k].feature_id,
                                        status: false
                                    });
                                }
                            }
                        }
                    }
                }



                // console.log(data)
                // return;

                // this.$store.dispatch('acl/update', [JSON.stringify(data)])
                this.$store.dispatch('acl/update', data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        this.getData();

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data berhasil diperbarui!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            Event.$emit('error', null, resp.data.message.errors);
                        } else {
                            Event.$emit('error', (resp.data.message ? resp.data.message : null));
                        }
                    }
                });

            } else {
                Event.$emit('error', 'Tidak ada data untuk disimpan.');
            }
        },
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.$store.commit('acl/setListRoleAccessControl', {});
            if (to.name === 'AclPage')
                vm.getData();
        });
    },
}
</script>

<style lang="sass">

</style>